@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Sora", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(29, 161, 242, 0.2);
  border-radius: 5px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a6872;
}

.common-table {
  position: relative;
  clear: both;
}

.common-table.group-table-wrapper > div {
  max-height: 500px;
}

.common-table::before {
  top: -2px;
  left: 0;
  right: 5px;
  height: 20px;
  content: "";
  z-index: 5;
  position: absolute;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
